import Icon from '@components/shared/Icon'
import mix from '@utils/styles/mix'
import styles from './HintInputIcon.module.css'

function HintInputIcon ({ action, children, className, icon }: { action: () => void | undefined, children: React.ReactNode, className: string | undefined, icon: string | undefined }) {
    const _className = mix([styles.icon, !action && styles.off, className])
    return <>
        {children}
        { icon && <Icon className={_className} onClick={action}>{icon}</Icon> }
    </>
}

export {
    HintInputIcon,
}