import Button from '@components/button/Button'

import useT from '@hooks/useTranslation'
import { ADD_OFFER_PAGE, LOGIN_PAGE } from '@constants/routes'
import { BUY_CAR } from '@constants/AddFormLinks'

const HOST = process.env.NEXT_PUBLIC_HOST
const buttonStyle = { paddingInline: 16 }
const buttonOutlineStyle = {
	background: '#fff',
	color: 'var(--teal)',
	border: '1px solid var(--teal)',
}

function AddBuyCarButton({ children, style, ...rest }) {
	const kupie = useT('Kupię auto')
	return (
		<Button
			href={HOST + BUY_CAR}
			style={{ ...buttonOutlineStyle, ...(style || {}) }}
			title="Kupię samochoód"
			{...rest}
		>
			{children || kupie}
		</Button>
	)
}

function AddOfferButton({ isLoggedIn, style, ...rest }) {
	return (
		<Button
			href={HOST + (isLoggedIn ? ADD_OFFER_PAGE : LOGIN_PAGE)}
			icon="add"
			style={style ? { ...buttonStyle, ...style } : buttonStyle}
			title="Dodaj ogłoszenie"
			blue
			{...rest}
		>
			<span>{useT('Dodaj ogłoszenie')}</span>
		</Button>
	)
}

function LoginButton(props) {
	return (
		<Button
			href={HOST + LOGIN_PAGE}
			icon="login"
			style={buttonStyle}
			title="Zaloguj się na portal ogłoszeń motoryzacyjnych"
			teal
			{...props}
		>
			{useT('Zaloguj się')}
		</Button>
	)
}

export { AddBuyCarButton, AddOfferButton, LoginButton }
