const BASE = '/dodaj-ogloszenie'
const SELL = `${BASE}/sprzedaj`
const RENT = `${BASE}/wynajmij`
const SERVICE = `${BASE}/serwis`
const BUY = `${BASE}/kup`

const CAR = '/samochod-osobowy'
const TRUCK = '/samochod-dostawczy'
const MOTOR = '/motocykl'
const PART = '/czesci'
const TOOL = '/narzedzia'
const AGRI = '/maszyne-rolnicza'
const CONSTRUCTION = '/maszyne-budowlana'
const TRAILER = '/przyczepe'
const HEAVY = '/samochod-ciezarowy'

const SELL_CAR = `${SELL}${CAR}`
const SELL_TRUCK = `${SELL}${TRUCK}`
const SELL_MOTOR = `${SELL}${MOTOR}`
const SELL_PART = `${SELL}${PART}`
const SELL_TOOL = `${SELL}${TOOL}`
const SELL_AGRI = `${SELL}${AGRI}`
const SELL_CONSTRUCTION = `${SELL}${CONSTRUCTION}`
const SELL_TRAILER = `${SELL}${TRAILER}`
const SELL_HEAVY = `${SELL}${HEAVY}`

const RENT_CAR = `${RENT}${CAR}`
const RENT_TRUCK = `${RENT}${TRUCK}`
const RENT_MOTOR = `${RENT}${MOTOR}`
const RENT_AGRI = `${RENT}${AGRI}`
const RENT_TRAILER = `${RENT}${TRAILER}`

const BUY_CAR = `${BUY}${CAR}`
const BUY_TRUCK = `${BUY}${TRUCK}`
const BUY_MOTOR = `${BUY}${MOTOR}`
const BUY_PART = `${BUY}${PART}`
const BUY_TOOL = `${BUY}${TOOL}`
const BUY_AGRI = `${BUY}${AGRI}`
const BUY_CONSTRUCTION = `${BUY}${CONSTRUCTION}`
const BUY_TRAILER = `${BUY}${TRAILER}`

const mainLinks = [
    { t: 'Sprzedaję', to: SELL_CAR },
    { t: 'Wynajmuję', to: RENT_CAR },
    { t: 'Oferuję usługę', to: SERVICE },
    { t: 'Chcę kupić', to: BUY_CAR },
]

const sellLinks = [
    { t: 'Samochód osobowy', to: SELL_CAR, svg: 'osobowe' },
    { t: 'Samochód dostawczy', to: SELL_TRUCK, svg: 'dostawcze' },
    { t: 'Motocykl', to: SELL_MOTOR, svg: 'motocykle' },
    { t: 'Części', to: SELL_PART, svg: 'czesci' },
    { t: 'Narzędzia', to: SELL_TOOL, svg: 'narzedzia' },
    { t: 'Maszynę rolniczą', to: SELL_AGRI, svg: 'rolnicze' },
    { t: 'Maszynę budowlaną', to: SELL_CONSTRUCTION, svg: 'budowlane' },
    { t: 'Przyczepę', to: SELL_TRAILER, svg: 'przyczepy' },
    { t: 'Samochód ciężarowy', to: SELL_HEAVY, svg: 'ciezarowe' },
]

const rentLinks = [
    { t: 'Samochód osobowy', to: RENT_CAR, svg: 'osobowe' },
    { t: 'Samochód dostawczy', to: RENT_TRUCK, svg: 'dostawcze' },
    { t: 'Motocykl', to: RENT_MOTOR, svg: 'motocykle' },
    { t: 'Maszynę Rolniczą', to: RENT_AGRI, svg: 'rolnicze' },
    { t: 'Przyczepę', to: RENT_TRAILER, svg: 'przyczepy' },
]

const serviceLinks = [
    { t: 'Warsztat lub serwis samochodowy', to: SERVICE, svg: 'serwis' },
]

const buyLinks = [
    { t: 'Samochód osobowy', to: BUY_CAR, svg: 'osobowe' },
    { t: 'Samochód dostawczy', to: BUY_TRUCK, svg: 'dostawcze' },
    { t: 'Motocykl', to: BUY_MOTOR, svg: 'motocykle' },
    { t: 'Części', to: BUY_PART, svg: 'czesci' },
    { t: 'Narzędzia', to: BUY_TOOL, svg: 'narzedzia' },
    { t: 'Maszynę Rolniczą', to: BUY_AGRI, svg: 'rolnicze' },
    { t: 'Maszynę Budowlaną', to: BUY_CONSTRUCTION, svg: 'budowlane' },
    { t: 'Przyczepę', to: BUY_TRAILER, svg: 'przyczepy' },
]

export {
    SELL_CAR,
    RENT_CAR,
    SERVICE,
    BUY_CAR,

    mainLinks,
    sellLinks,
    rentLinks,
    serviceLinks,
    buyLinks,
}