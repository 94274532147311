import type { Hints, Filter } from './HintDisplay'
import { renderHints } from './HintDisplay'
import mix from '@utils/styles/mix'
import styles from './HintDisplayDesktop.module.css'

function HintDisplayDesktop ({
	children,
	className,
	filter,
	focus,
	hints,
	onChange,
	staticValues,
}: {
	children: React.ReactNode,
	className: string,
	filter: Filter,
	focus: boolean,
	hints: Hints,
	onChange: (e: any, value: string) => void,
	staticValues: Hints,
}) {
	const displayHints = Boolean(focus && Array.isArray(hints) && hints.length)

    return (
		<div className={mix([styles.wrap, className])}>
			{ children }
			{ displayHints && (
				<div className={styles.hints}>
				{ renderHints(staticValues, hints, filter, onChange) }
				</div>
			) }
		</div>
    )
}

export {
    HintDisplayDesktop,
}