"use client"

import { useCallback, useEffect, useState } from 'react'
import { HintDisplayDesktop } from './HintDisplayDesktop'
import { HintDisplayMobile } from './HintDisplayMobile'
import { HintInputIcon } from './HintInputIcon'
import Maybe from '@components/layout/Maybe'
import lowCase from '@utils/string/lowCase'
import { useInputValueStore } from './InputValueStoreProvider'
import { markStoreBinded, removeMark } from './utils/markInputStoreBind'
import { setHtmlValue } from './utils/setHtmlValue'
import { phrase } from '@utils/string/phrase'

import styles from './Hint.module.css'

const DisplayVariants = {
    Desktop: HintDisplayDesktop,
    Mobile: HintDisplayMobile,
}

function useInput (name, onChange) {
    const [focus, setFocus] = useState(false)
    const onBlur = useCallback(() => setFocus(false), [])
    const onFocus = useCallback(() => setFocus(true, ), [])

    useEffect(() => {
        const input = document.querySelector(`[name="${name}"]`)
        if (!input) return

        input.addEventListener('blur', onBlur)
        input.addEventListener('input', onChange)
        input.addEventListener('focus', onFocus)
        markStoreBinded(input)

        return () => {
            input.removeEventListener('blur', onBlur)
            input.removeEventListener('input', onChange)
            input.removeEventListener('focus', onFocus)
            removeMark(input)
        }
    }, [name, onBlur, onChange, onFocus])

    return focus
}

function Hint ({ variantOverride, ...props }) {
    return <>
        <Maybe.Desktop><_Hint variant={variantOverride || "Desktop"} {...props} /></Maybe.Desktop>
        <Maybe.Mobile><_Hint variant={variantOverride || "Mobile"} {...props} /></Maybe.Mobile>
    </>
}

function _Hint ({
    allowOnly,
    children,
    deps,
    exact,
    exactStart,
    isDepFor,
    modeTextIncluded,
    name,
    placeholder,
    select,
    staticValues,
    url,
    variant = 'Mobile',
}) {
    const [hints, setHints] = useState(null)
    const [lastHintsUrl, setLastHintsUrl] = useState('')
    const { getValue, setValue } = useInputValueStore()
    const clear = useCallback(() => {
        const toClear = [name, ...(isDepFor || [])]
        for (const name of toClear) {
            setValue(name, '')
            setHtmlValue(name, '')
        }
    }, [isDepFor, name, setValue])
    const okCheck = useValidator(hints, { allowOnly, exact, exactStart, modeTextIncluded, select })
    const onChange = useCallback((e, val) => {
        let text = val || e.target.value
        if (okCheck && !okCheck(text)) text = text.slice(0, -1)
        if (isDepFor) clear()
        setValue(name, text)
        setHtmlValue(name, text)
    }, [clear, isDepFor, name, okCheck, setValue])
    const focus = useInput(name, onChange)
    const hintsUrl = useHintUrl(url, deps)
    useHints(hintsUrl, focus, lastHintsUrl, setHints, setLastHintsUrl)
    const value = getValue(name)
    const filter = useHintFilter(value, modeTextIncluded)
    const loading = focus && hintsUrl && !hints
    const disabled = (!hintsUrl || (!loading && hints?.length < 1)) && hintsUrl == lastHintsUrl

    const Display = DisplayVariants[variant]

    return (
        <Display
            className={disabled && styles.disabled}
            filter={filter}
            focus={focus}
            hints={hints}
            onChange={onChange}
            placeholder={placeholder}
            staticValues={staticValues}
        >
            <HintInputIcon action={clear} className={styles['clear-icon']} icon={value ? 'close' : ''}>
                <HintInputIcon className={styles[`${loading ? 'loading' : 'toggle'}-icon`]} icon={loading ? 'sync' : `expand_${focus && hints ? 'less' : 'more'}`}>
                    {children}
                </HintInputIcon>
            </HintInputIcon>
        </Display>
    )
}

function useValidator (hints, { allowOnly, exact, exactStart, modeTextIncluded, select }) {
    if (!hints) return
    if (allowOnly) return (value) => new RegExp(allowOnly, 'i').test(value)
    if (exact || exactStart) return (value) => hints.some(hint => lowCase(hint).startsWith(lowCase(value)))
    if (select) return (value) => hints.some(hint => lowCase(hint) === lowCase(value))
    if (modeTextIncluded) return (value) => hints.some(hint => phrase(lowCase(hint)).includesAllWordsFrom(lowCase(value)))
}

function useHintFilter (value, modeInclude) {
    const lowV = lowCase(value)
    return (hint) => lowCase(hint)[modeInclude ? 'includes' : 'startsWith'](lowV)
}

function useHintUrl (url, deps) {
    const { getValues, hasValues } = useInputValueStore()
    if (!hasValues(deps)) return ''
    const depValues = deps && getValues(deps)
    const query = deps && deps.map((name, idx) => `${name}=${encodeURIComponent(depValues[idx])}`).join('&')
    return [`/api/hints${url}`, query].filter(Boolean).join('?')
}

function useHints (url, focus, lastUrl, setHints, setLastUrl) {
    useEffect(() => {
        if (!focus || !url || url === lastUrl) return
        setHints(null)
        setLastUrl(url)
        fetch(url, { next: { cache: 'force-cache', revalidate: 60 }})
            .then(res => res.json())
            .then(setHints)
            .catch(err => console.log(err))
    }, [focus, lastUrl, setHints, setLastUrl, url])
}

export {
    Hint,
}