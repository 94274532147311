const ATTR = 'data-store-bind'

const markStoreBinded = (input) => {
    input.setAttribute(ATTR, 'on')
}

const removeMark = (input) => {
    input.removeAttribute(ATTR)
}

export {
    ATTR,

    markStoreBinded,
    removeMark,
}