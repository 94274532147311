"use client"

import { createContext, useContext,
    useCallback, useEffect, useRef, useState } from 'react'
import toArray from '@utils/array/toArray'
import { ATTR, markStoreBinded, removeMark } from './utils/markInputStoreBind'
import createSlug from '@utils/slug/createSlug'
import { charsOkInComponent } from '@constants/slugs'

const InputValueContext = createContext({})
const useInputValueStore = () => useContext(InputValueContext)

function InputValueStateProvider ({ children, defaultValues }) {
    const value = useStore(defaultValues || {})
    const ref = useInputValueChangeTracker(['input','textarea'], value.setValue)

    useInputInitialValueSetter(defaultValues)

    return (
        <InputValueContext.Provider value={value}>
            <div ref={ref}>{children}</div>
        </InputValueContext.Provider>
    )
}

function useStore (defaultValues) {
    const [store, setStore] = useState(defaultValues)
    const getValue = useCallback((key) => store[key], [store])
    const getValues = useCallback((keys) => keys.map(key => store[key]), [store])
    const hasValues = useCallback((keys) => toArray(keys).every(key => Boolean(store[key])), [store])
    const setValue = useCallback((key, value) => setStore(store => ({ ...store, [key]: value })), [setStore])
    return { getValue, getValues, hasValues, setValue, setStore, store }
}

function useInputValueChangeTracker (elements, setValue) {
    const ref = useRef()

    useEffect(() => {
        const wrap = ref.current
        const inputs = wrap.querySelectorAll(elements.map(el => `${el}:not([${ATTR}])`).join(','))
        if (!inputs) return
        const iterable = [...inputs]

        const setter = ({ target: { checked, name, type, value }}) => {
            const _value = String((type == 'checkbox' ? checked : value) || '')
            setValue(name, _value)
        }

        const onHandler = input => {
            input.addEventListener('input', setter)
            markStoreBinded(input)
        }

        const offHandler = input => {
            input.removeEventListener('input', setter)
            removeMark(input)
        }

        iterable.forEach(onHandler)
        return () => iterable.forEach(offHandler)
    }, [elements, setValue])

    return ref
}

function useInputInitialValueSetter (defaults) {
    useEffect(() => {
        if (!defaults) return
        Object.entries(defaults).forEach(([name, value]) =>
            setTimeout(() => {
                let input = document.querySelector(`[name="${name}"]`)
                if (!input) input = document.querySelector(`[name="${createSlug(name, { additionalyAllowed: charsOkInComponent })}"]`)
                if (input) input.type == 'checkbox'
                    ? (input.checked = true)
                    : (input.value = value)
            }, 1)
        )
    }, [defaults])
}

export {
    InputValueStateProvider,
    useInputValueStore,
}