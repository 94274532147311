"use client"

import Link from 'next/link'
import { useEffect, useState } from 'react'
import { usePathname } from 'next/navigation'
import useTranslatedArray from '@hooks/useTranslatedArray'
import useOnClickOutside from '@hooks/useOnClickOutside'
import useT from '@hooks/useTranslation'
import mix from '@utils/styles/mix'

import styles from './SearchFormServer.module.css'

function Links ({ active, bold, links, ...rest }) {
    const className = mix([
		styles['form-links'],
		bold && styles['bold'],
	])
    const pathname = usePathname()
    const translations = useTranslatedArray(links.map((link) => link.t))

	useEffect(() => {
		const active = document.querySelector(`.${styles.active}`)
		if (active) active.parentElement.scrollLeft = active.offsetLeft - 20
	}, [])

	return (
        <div className={className} {...rest}>
			{links.map((link, idx) => {
				if (Array.isArray(link))
					return <FormLinkDropdown FormLinks={Links} from={link} key={idx} />
				const { to, alt, title } = link
				const isActive =
					active == idx || pathname.startsWith(to)
				const className = mix([
					styles['form-link'],
					isActive && styles.active,
				])
				return (
                    (<Link
                        href={to}
                        key={to}
                        prefetch={false}
                        alt={alt}
                        className={className}
                        title={title}>

                        {translations[idx]}

                    </Link>)
                );
			})}
		</div>
    );
}

function FormLinkDropdown({ FormLinks, from }) {
	const [show, setShow] = useState(false)
	const toggle = () => setShow((show) => !show)

	const ref = useOnClickOutside(() => setShow(false), null, [
		'dropdown-link',
	])

	const [t, ...links] = from
	const className = mix([
		styles['dropdown-inner'],
		show && styles.open,
	])
	return (
		<div
			className={styles['form-link']}
			onClick={toggle}
			role="button"
			style={{ width: 170, maxWidth: 'none' }}
		>
			<div className="dropdown-link" style={{ position: 'absolute' }}>
				{useT(t)}
				<div
					className={className}
					ref={ref}
					style={{ width: 'fit-content', paddingBottom: 20 }}
				>
					<FormLinks
						links={links}
						style={{ flexDirection: 'column' }}
					/>
				</div>
			</div>
		</div>
	)
}

export {
    FormLinkDropdown,
	Links,
}