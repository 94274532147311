import blurActive from '@utils/dom/blurActive'
import styles from './HintDisplay.module.css'

type Filter = ((hint: string) => boolean)
type Hints = string[]

const HINT_DIVIDER = '--'

function mergeHints(hints1: string[], hints2: string[]): string[] {
	if (!Array.isArray(hints2)) return hints1
	if (!Array.isArray(hints1)) return hints2
	return [...hints1, HINT_DIVIDER, ...hints2]
}

function renderHints (hints1: Hints, hints2: Hints, filter: Filter, onChange: (a: any, b: string) => void) {
	return mergeHints(hints1, hints2)
		.filter(filter)
		.map((hint) => {
			const isDivider = hint === HINT_DIVIDER
			const className = isDivider ? styles.disabled : undefined
			const onMouseDown = (e) => {
				e.preventDefault()
				onChange('', hint)
				blurActive()
			}
			return (
				<p
					className={className}
					key={hint}
					onMouseDown={onMouseDown}
				>
					{hint}
				</p>
			)
		})
}

export {
	HINT_DIVIDER,

	mergeHints,
	renderHints,
}

export type {
	Filter,
	Hints,
}