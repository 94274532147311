import { useCallback, useEffect, useState } from 'react'
import type { Filter, Hints } from './HintDisplay'
import { renderHints } from './HintDisplay'
import Icon from '@components/shared/Icon'
import lowCase from '@utils/string/lowCase'
import mix from '@utils/styles/mix'
import useT from '@hooks/useTranslation'

import styles from './HintDisplayMobile.module.css'

type Callback = (e: any, value: string) => void

function useDisplayState (focus: boolean, hints: Hints, staticValues: Hints, onChange: Callback) {
	const [display, setDisplay] = useState(false)
	const [filterValue, setFilterValue] = useState('')

	useEffect(() => {
		if (display || !focus || !hints?.length) return
		setDisplay(true)
	}, [focus, hints, display, setDisplay])

	const close = useCallback(() => {
		setFilterValue('')
		setDisplay(false)
	}, [setFilterValue, setDisplay])

	const _onChange = useCallback((e: any, value: string) => {
		onChange(e, value)
		close()
	}, [close, onChange])

	const onFilter = useCallback(({ target: { value }}: { target: { value: string }}) => {
		const comp = Array.isArray(staticValues) ? staticValues.concat(hints || []) : hints
		if (comp.some((hint: string) => lowCase(hint).startsWith(lowCase(value)))) {
			setFilterValue(value || '')
		}
	}, [hints, setFilterValue, staticValues]
)
	const filter = useCallback((hint: string) => lowCase(hint).startsWith(lowCase(filterValue)), [filterValue]) as Filter

	return [_onChange, close, display, filter, filterValue, onFilter] as [Callback, React.MouseEventHandler<HTMLDivElement>, boolean, Filter, string, React.ChangeEventHandler<HTMLInputElement>]
}

function HintDisplayMobile ({
	children,
	className,
	focus,
	hints,
	onChange,
	placeholder,
	staticValues,
}: {
	children: React.ReactNode,
	className: string,
	focus: boolean,
	hints: Hints,
	onChange: Callback,
	placeholder: string | undefined,
	staticValues: Hints,
}) {
	const t = {
		close: useT('Zamknij'),
		search: useT('Wyszukaj') + '...',
	}

	const [_onChange, close, display, filter, filterValue, onFilter] = useDisplayState(focus, hints, staticValues, onChange)

	// useEffect(() => {
	// 	if (display) {
	// 		document.activeElement?.blur()
	// 		scrollTo(0,0)
	// 	}
	// }, [display])

    return (
		<div className={mix([styles.wrap, className])}>
			{ children }
			{ display && hints?.length > 0 && (
				<div className={styles.flex}>
					<div className={styles.close} onClick={close} role="button">
						<Icon>close</Icon> {t.close}
					</div>
					<div className={styles.ip}>
						{ placeholder && <label className={styles.label} htmlFor="filtr">{placeholder}</label> }
						<input
							className={styles.input}
							id="filtr"
							name="filtr"
							onChange={onFilter}
							placeholder={t.search}
							type="text"
							value={filterValue}
							autoFocus
						/>
					</div>
					<div className={styles.hints}>
						{ renderHints(staticValues, hints, filter, _onChange) }
					</div>
				</div>
			) }
		</div>
    )
}

export {
    HintDisplayMobile,
}