import { useRef, useEffect } from 'react'
import toArray from '@utils/array/toArray'

const useOnClickOutside = (
	callback,
	optionalAllowedParentClass,
	skipClass,
) => {
	const ref = useRef()

	useEffect(() => {
		const handler = (e) => {
			if (!ref.current) return
			if (
				skipClass &&
				toArray(skipClass).some((name) =>
					e.target.classList.contains(name),
				)
			)
				return
			if (!ref.current.contains(e.target)) {
				if (optionalAllowedParentClass) {
					const allowedAlso = document.querySelector(
						`.${optionalAllowedParentClass}`,
					)
					if (!allowedAlso?.contains(e.target)) {
						callback()
					}
				} else {
					callback()
				}
			}
		}

		document.addEventListener('mousedown', handler)
		return () => document.removeEventListener('mousedown', handler)
	}, [callback, optionalAllowedParentClass, skipClass])

	return ref
}

export default useOnClickOutside
