"use client"

import useMediaQueryBreakpoint from '@hooks/useMediaQueryBreakpoint'
import BREAKPOINTS from '@constants/breakpoints'

const { showHamburger: mobileBreakpoint } = BREAKPOINTS

function Desktop ({ children, defaultValue }) {
    const onMobile = useMediaQueryBreakpoint(mobileBreakpoint, defaultValue)
    return onMobile
        ? null
        : children
}

function Mobile ({ children, defaultValue }) {
    const onMobile = useMediaQueryBreakpoint(mobileBreakpoint, defaultValue)
    return onMobile
        ? children
        : null
}

function Maybe () { return null }

Maybe.Desktop = Desktop
Maybe.Mobile = Mobile

export default Maybe
export {
    Mobile,
    Desktop,
}