import { useEffect, useState } from 'react'

const useLocalStorage = (key, defaultValue = '') => {
	const [value, setValue] = useState(
		() =>
			(typeof localStorage !== 'undefined' &&
				localStorage.getItem(key)) ||
			defaultValue,
	)

	useEffect(() => {
		localStorage[value ? 'setItem' : 'removeItem'](key, value)
	}, [key, value])

	return [value, setValue]
}

export default useLocalStorage